import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import HomeSections from "./HomeSections"
import Img from "gatsby-image"

const Acoge = ({ id }) => {
  const image = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "acogeauncientifico.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <HomeSections
      id={id}
      title="Acoge a un Científico"
      subtitle="Reccoriendo el mundo, divulgando ciencia"
    >
      <div className="container">
        <div className="columns is-vcentered is-centered">
          <div className="column is-two-fifths">
            <div className="content">
              <p>
                Cuando terminé mi doctorado decidí cambiar la investigación por
                la divulgación y la educación. Junto con mi compañero Ángel puse
                en marcha un proyecto de divulgación científica llamado{" "}
                <b>Acoge a un Científico</b>, cuyo objetivo principal es
                divulgar ciencia a lo largo de Latinoamérica.
              </p>
              <p>
                En dos años y medio realizamos más de 300 actividades en 185
                instituciones en 12 países. En{" "}
                <a
                  href="https://www.acogeauncientifico.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <b>nuestra web</b>
                </a>{" "}
                puedes encontrar más información sobre el proyecto y nuestras
                aventuras en 1001 días recorriendo el mundo divulgando ciencia.
              </p>
            </div>
          </div>
          <div className="column  is-4 ">
            <a
              href="https://www.acogeauncientifico.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Img
                fluid={image.file.childImageSharp.fluid}
                alt="Acoge a un científico"
              />
            </a>
          </div>
        </div>
      </div>
    </HomeSections>
  )
}

export default Acoge
