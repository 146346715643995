import React from "react"

import HomeSections from "./HomeSections"

const Videos = ({ id }) => {
  return (
    <HomeSections id={id} title="Videos" subtitle="Youtube" isLight>
      <div className="container">
        <div className="columns is-centered">
          <div className="column is-half">
            <div className="iframe-container">
              <iframe
                src="https://www.youtube.com/embed/8qTcaNE-dPY"
                title="Nuevo Video"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                frameBorder="0"
                webkitallowfullscreen="true"
                mozallowfullscreen="true"
                allowFullScreen
              />
            </div>
          </div>
        </div>
      </div>
    </HomeSections>
  )
}

export default Videos
