import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import { FaFileDownload } from "react-icons/fa"

import HomeSections from "./HomeSections"
import Img from "gatsby-image"

const Ani = ({ id }) => {
  const data = useStaticQuery(graphql`
    {
      pdf: file(name: { eq: "cv" }) {
        publicURL
      }
      img: file(relativePath: { eq: "Ana.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <HomeSections id={id} title="Ani" subtitle="de Anisotropía">
      <div className="container">
        <div className="columns is-vcentered is-centered">
          <div className="column  is-4 ">
            <Img fluid={data.img.childImageSharp.fluid} alt="Ana I. Salvador" />
          </div>
          <div className="column is-two-fifths">
            <div className="content">
              <p>
                Soy Ana, una apasionada de la física y la cosmología. Desde
                pequeña me han llamado la atención las estrellas y el por qué de
                las cosas. Por eso estudié Física y después hice un doctorado en
                cosmología en el{" "}
                <a
                  href="https://www.ift.uam-csic.es"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <b>IFT</b>
                </a>
                .
              </p>
              <p>
                Cuando terminé mi doctorado decidí cambiar la investigación por
                la divulgación y la educación. Ahora quiero invitarte a hacer un
                viaje por el universo. Así que si eres de esas personas que ha
                mirado alguna vez al cielo y se ha hecho preguntas, ¡súbete que
                vamos!
              </p>
            </div>
            <a
              href={data.pdf.publicURL}
              target="_blank"
              className="button is-primary"
              rel="noopener noreferrer"
            >
              <span className="icon">
                <FaFileDownload />
              </span>
              <span> CV</span>
            </a>
          </div>
        </div>
      </div>
    </HomeSections>
  )
}

export default Ani
