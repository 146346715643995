import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"

import BackgroundImage from "gatsby-background-image"

const Hero = ({ id }) => {
  const image = useStaticQuery(graphql`
    {
      anaImage: file(relativePath: { eq: "background_ana.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      backgroundDesktopImage: file(
        relativePath: { eq: "background_fondo.png" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const backgroundDesktop = [
    image.anaImage.childImageSharp.fluid,
    image.backgroundDesktopImage.childImageSharp.fluid,
  ]

  return (
    <BackgroundImage
      Tag="section"
      id={id}
      className="hero is-primary is-fullheight-with-navbar"
      fluid={backgroundDesktop}
      backgroundColor={`#040e18`}
    >
      <div className="hero-body">
        <div className="container has-text-centered"></div>
      </div>
    </BackgroundImage>
  )
}

const StyledHero = styled(Hero)`
  width: 100%;
  background-repeat: no-repeat, no-repeat;
  background-size: cover;
  background-color: transparent;
  background-position: center, center;
`

export default StyledHero
