import React from "react"
import HomeSections from "./HomeSections"
import FormContacto from "./FormContacto"

const Contacto = ({ id }) => {
  return (
    <HomeSections id={id} title="Contacto" isLight>
      <div className="columns is-centered  ">
        <div className="column is-6">
          <FormContacto />
        </div>
      </div>
    </HomeSections>
  )
}

export default Contacto
