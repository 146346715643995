import React from "react"

import Layout from "../components/Layout"
import Hero from "../components/Hero"
import HeroMobile from "../components/HeroMobile"
import Ani from "../components/Ani"
import Videos from "../components/Videos"
import Acoge from "../components/Acoge"
import Contacto from "../components/Contacto"

export default () => (
  <Layout>
    <Hero id="hero" />
    <HeroMobile id="heroMobile" />
    <Ani id="ani" />
    <Videos id="videos" />
    <Acoge id="acogeauncientifico" />
    <Contacto id="contacto" />
  </Layout>
)
